
import AddUserToCognitoModal from "@/components/AddUserToCognitoModal.vue";
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import HandleCompanyRoleModal from "@/components/HandleCompanyRoleModal.vue";
import DeleteCompanyServiceModal from "@/components/DeleteCompanyServiceModal.vue";
import EditCompanyServiceModal from "@/components/EditCompanyServiceModal.vue";
import Modal from "@/components/Modal.vue";
import { formatDateFromApi } from "@/plugins/moment";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import DatePicker from "@/components/DatePicker.vue";
import { getDurationLabelFromValue } from "@/utils/services";
import ServicesSelector from "@/components/ServicesSelector.vue";
import ServicesSubSelector from "@/components/ServicesSubSelector.vue";
import SelectedServices from "@/components/SelectedServices.vue";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    HandleCompanyRoleModal,
    DeleteCompanyServiceModal,
    AddUserToCognitoModal,
    DatePicker,
    ServicesSelector,
    ServicesSubSelector,
    SelectedServices,
    EditCompanyServiceModal,
  },
  watch: {
    selectedServices(val) {
      if (!this.showEditCompanyServiceModal) {
        this.resetSelectedServicesSub();
      }
    },
  },
})
export default class CompanyServicesTable extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.companyEmployees) employees;
  @State((state: StateStore) => state.data.companyServices)
  companyServices: Api.CompanyService[];
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @State((state: StateStore) => state.data.selectedServicesSub)
  selectedServicesSub: Api.ServiceSub[];
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedServicesSub;
  @Mutation resetSelectedServices;
  @Action getCompanyServicesById;

  @Prop()
  company;

  showDeleteCompanyServiceModal = false;
  deletingCompanyService = null;
  showEditCompanyServiceModal = false;
  editingCompanyService = null;

  startDate = null;
  endDate = null;
  services = [];
  loading = false;

  mounted() {
    this.resetSelectedServicesSub();
    this.resetSelectedServices();
    this.getServices();
  }

  async getServices() {
    this.loading = true;
    this.services = [];
    try {
      await this.getCompanyServicesById({
        companyGuid: this.company.guid,
        serviceGuid: this.selectedServices[0]
          ? this.selectedServices[0].guid
          : "",
        serviceSubGuid: this.selectedServicesSub[0]
          ? this.selectedServicesSub[0].guid
          : "",
        from: this.startDate,
        to: this.endDate,
      });
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  headers = [
    {
      text: "Servizio",
      align: "start",
      sortable: true,
      value: "service",
    },
    {
      text: "Sottoservizio",
      align: "start",
      sortable: false,
      value: "servicesub",
    },
    {
      text: "Note",
      align: "start",
      sortable: false,
      value: "notes",
    },
    {
      text: "Referente CNA",
      align: "start",
      sortable: false,
      value: "referent",
    },
    {
      text: "Scadenza",
      align: "start",
      sortable: true,
      value: "due_date",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];

  capitalize(text) {
    return _.capitalize(text);
  }

  getModalKey() {
    return Math.random();
  }

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }

  formatDate(date) {
    return formatDateFromApi(date, "DD/MM/YYYY");
  }

  getDurationLabelFromValue(value) {
    return getDurationLabelFromValue(value);
  }

  getSelectedServiceGuid() {
    if (this.selectedServices[0] == null) {
      return null;
    } else {
      return this.selectedServices[0].guid;
    }
  }
}
