import { render, staticRenderFns } from "./CompanyEmployeesTable.vue?vue&type=template&id=c860d5d8&scoped=true&"
import script from "./CompanyEmployeesTable.vue?vue&type=script&lang=ts&"
export * from "./CompanyEmployeesTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c860d5d8",
  null
  
)

export default component.exports