var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal',_vm._g(_vm._b({attrs:{"width":"600","title":"Modifica Contatti Aziendale","showActions":"true","showClose":"true","disablePositiveButton":!_vm.allowSave,"positiveButton":"Salva","negativeButton":"Annulla"},on:{"positive-click":function($event){return _vm.updateCompany()},"negative-click":function($event){return _vm.onClose()},"close":function($event){return _vm.onClose()}}},'modal',_vm.$attrs,false),_vm.$listeners),[(_vm.editCompany)?_c('v-form',{ref:"editCompanyForm",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2"},[_c('v-text-field',{ref:"emailField",attrs:{"outlined":"","dense":"","rules":_vm.editCompany.email == null
              ? []
              : _vm.editCompany.email.length > 0
              ? _vm.emailRules
              : [],"validate-on-blur":"","type":"text","label":"Email Azienda","color":"accent"},model:{value:(_vm.editCompany.email),callback:function ($$v) {_vm.$set(_vm.editCompany, "email", $$v)},expression:"editCompany.email"}})],1),_c('v-col',{staticClass:"pl-2"},[_c('v-text-field',{ref:"pecField",attrs:{"outlined":"","dense":"","rules":_vm.editCompany.emailpec == null
              ? []
              : _vm.editCompany.emailpec.length > 0
              ? _vm.pecRules
              : [],"validate-on-blur":"","type":"text","label":"PEC Azienda","color":"accent"},model:{value:(_vm.editCompany.emailpec),callback:function ($$v) {_vm.$set(_vm.editCompany, "emailpec", $$v)},expression:"editCompany.emailpec"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2"},[_c('v-text-field',{ref:"phoneField",attrs:{"outlined":"","dense":"","rules":[],"validate-on-blur":"","type":"text","label":"Numero di telefono","color":"accent"},model:{value:(_vm.editCompany.phone),callback:function ($$v) {_vm.$set(_vm.editCompany, "phone", $$v)},expression:"editCompany.phone"}})],1),_c('v-col',{staticClass:"pl-2"})],1),_c('div',{staticClass:"body d-flex justify-space-between mt-6"},[_c('span',[_vm._v("Per procedere clicca su Conferma e poi su Salva")]),_c('btn',{staticClass:"white--text text-capitalize mb-4 mr-6",attrs:{"color":"accent","small":""},on:{"click":function($event){_vm.allowSave = true}}},[_vm._v(" Conferma ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }