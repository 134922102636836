
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import LabelValueListItem from "@/components/LabelValueListItem.vue";
import Modal from "@/components/Modal.vue";
import _ from "lodash";
import PrivacyModalContent from "@/components/PrivacyModalContent.vue";
import EditCompanyRegistryModal from "@/components/EditCompanyRegistryModal.vue";
import CompanyReferentsTable from "@/components/CompanyReferentsTable.vue";
import CompanyReferentsCnaTable from "@/components/CompanyReferentsCnaTable.vue";
import CompanyEmployeesTable from "@/components/CompanyEmployeesTable.vue";
import CompanyServicesTable from "@/components/CompanyServicesTable.vue";
import EditCompanyContactsModal from "@/components/EditCompanyContactsModal.vue";
import EditCompanyNotesModal from "@/components/EditCompanyNotesModal.vue";
import EditCompanyAffiliationModal from "@/components/EditCompanyAffiliationModal.vue";
import AssignServicesToCompanyModal from "@/components/AssignServicesToCompanyModal.vue";
import CompanyDocuments from "@/components/CompanyDocuments.vue";
import AddCompanyDocumentModal from "@/components/AddCompanyDocumentModal.vue";
import AddCompanyEmployeeModal from "@/components/AddCompanyEmployeeModal.vue";
import ProfileAvatarDialog from "@/components/ProfileAvatarDialog.vue";
import * as Api from "@/types/graphql";
import gql from "graphql-tag";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { formatAtecoCode } from "../utils/ateco";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import AssignReferentCNAToCompanyModal from "./AssignReferentCNAToCompanyModal.vue";

@Component({
  components: {
    Card,
    Btn,
    LabelValueListItem,
    ProfileAvatarDialog,
    Modal,
    PrivacyModalContent,
    EditCompanyRegistryModal,
    EditCompanyContactsModal,
    EditCompanyAffiliationModal,
    AddCompanyDocumentModal,
    CompanyReferentsTable,
    AssignServicesToCompanyModal,
    CompanyDocuments,
    AddCompanyEmployeeModal,
    CompanyEmployeesTable,
    CompanyServicesTable,
    CompanyReferentsCnaTable,
    AssignReferentCNAToCompanyModal,
    EditCompanyNotesModal
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class CompanyData extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.company) company: Api.Company;
  @State((state: StateStore) => state.data.services) services: Api.Service[];
  @Mutation setCompanyReferents;
  @Mutation setCompanyEmployees;
  @Action fetchCompany;
  @Mutation showSuccess;
  @Mutation showError;
  @Prop()
  companyGuid: string;
  @Prop({ default: true })
  showSubtitle: string;

  tabKeys = ["companyTabs.registry", "companyTabs.contacts"];
  componentKey = 1;

  employees = null;

  showEditCompanyRegistryModal = null;
  showEditCompanyContactsModal = null;
  showEditCompanyAffiliationModal = null;
  showAddCompanyDocumentModal = null;
  showAddCompanyEmployeeModal = null;
  showHandleCompanyRoleModal = null;
  showAssignServicesToCompanyModal = null;
  showAssignReferentCNAToCompanyModal = null;
  showEditCompanyNotesModal = null;

  getModalKey() {
    return Math.random();
  }

  async mounted() {
    if (checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2])) {
      this.tabKeys = [
        "companyTabs.registry",
        "companyTabs.contacts",
        "companyTabs.affiliation",
        "companyTabs.employees",
        //"companyTabs.cnaReferents",
        "companyTabs.documents",
        "companyTabs.services",
        "companyTabs.notes",
      ];
    }
    await this.getCompany();
  }

  async getCompany() {
    await this.fetchCompany(this.companyGuid);
    this.employees = this.company.employees_num;
    this.company.services.forEach((companyService) => {
      companyService.service = _.find(this.services, {
        guid: companyService.service_guid,
      });
    });
    this.componentKey++;
  }

  formatAtecoCode(code) {
    return formatAtecoCode(code);
  }

  getCompanyToEdit() {
    return _.clone(this.company);
  }

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }

  isAdminLevel1() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1]);
  }

  async updateCompany() {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation company_update($guid: ID!, $employees: Int) {
          updateCompany(guid: $guid, employees_num: $employees)
        }
      `,
      variables: {
        guid: this.companyGuid,
        employees: parseInt(this.employees),
      },
    });

    if (res.data.updateCompany) {
      this.showSuccess("Azienda modificata correttamente");
      this.getCompany();
    } else {
      this.showError("Errore durante modifica azienda");
    }
  }
}
