
import Btn from "@/components/Btn.vue";
import Modal from "@/components/Modal.vue";
import gql from "graphql-tag";
import _ from "lodash";
import HeadquarterSelector from "@/components/HeadquarterSelector.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    HeadquarterSelector,
    Btn,
  },
  watch: {},
})
export default class EditCompanyAffiliationModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedHeadquarter;

  isFormValid = false;
  allowSave = false;

  @Prop()
  company;
  @Prop({ default: "all" })
  editSection;

  editCompany = null;
  selectedHeadquarter = null;

  async mounted() {
    this.editCompany = this.company;
    await this.setSelectedHeadquarter(this.company.headquarter);
    this.selectedHeadquarter = this.company.headquarter;
  }

  onClose() {
    this.allowSave = false;
    this.$emit("close");
  }

  resetValues() {
    (this.$refs.editCompanyForm as Vue & { reset: () => void })?.reset();
  }

  async updateCompany() {
    if (
      (this.$refs.editCompanyForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation company_update(
            $guid: ID!
            $companyCode: String
            $trade: String
            $headquarter: String
          ) {
            updateCompany(
              guid: $guid
              id_company: $companyCode
              trade: $trade
              headquarter_guid: $headquarter
            )
          }
        `,
        variables: {
          guid: this.editCompany.guid,

          companyCode: this.editCompany["id_company"],
          trade: this.editCompany.trade,
          headquarter: this.selectedHeadquarter.guid,
        },
      });

      if (res.data.updateCompany) {
        this.$emit("companyUpdated", res.data.updateCompany);
        this.showSuccess("Azienda modificata correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante modifica azienda");
      }
    }
  }
}
