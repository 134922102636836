
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import * as Api from "@/types/graphql";
import { RoleCodes } from "@/utils/roles";
import { checkUserStatus, StatusCodes } from "@/utils/status";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";
import gql from "graphql-tag";

@Component({
  components: {
    Btn,
    Card,
    UsersSelector,
    SelectedUsers,
    Modal,
  },
  watch: {
    selectedUsers(val, prev) {
      if (val.length > 0) {
        if (checkUserStatus(val[0], [StatusCodes.ADMIN2_RES_AREA])) {
          this.area = this.getArea([val[0].macroarea_guid])[0];
          this.services = [];
        }
        if (checkUserStatus(val[0], [StatusCodes.ADMIN2_RES_SER])) {
          this.area = null;
          this.services = val[0].services;
        }
      }
    },
  },
})
export default class AssignReferentCNAToCompanyModal extends Vue {
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsers: Api.User[];
  @Getter getRole: (code: string) => Api.Role;
  @Mutation resetSelectedUsers;
  @Mutation showError;
  @Mutation showSuccess;
  @Mutation removeSelectedUser;
  @Getter getArea;
  @Getter getSubGroup;

  loading = false;

  area = null;
  services = [];
  selectedServices = [];

  @Prop()
  company;

  getRoleFilter() {
    return [this.getRole(RoleCodes.ADMIN2).guid];
  }

  getStatusFilter() {
    return [StatusCodes.ADMIN2_RES_AREA, StatusCodes.ADMIN2_RES_SER];
  }

  isSelectedService(service) {
    return this.selectedServices.includes(service.guid);
  }

  toggleService(service) {
    if (this.isSelectedService(service)) {
      this.selectedServices = this.selectedServices.filter((s) => {
        return s != service.guid;
      });
    } else {
      this.selectedServices.push(service.guid);
    }
  }

  async onConfirm() {
    this.loading = true;
    const companyReferentCNA = {};
    companyReferentCNA["company_guid"] = this.company.guid;
    companyReferentCNA["referent_guid"] = this.selectedUsers[0].guid;
    if (this.selectedServices.length > 0) {
      companyReferentCNA["services"] = this.selectedServices;
    } else {
      companyReferentCNA["services"] = null;
    }
    if (this.area != null) {
      companyReferentCNA["areas"] = [this.area.guid];
    } else {
      companyReferentCNA["areas"] = null;
    }

    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation company_update(
          $guid: ID!
          $referentsCNA: [CompanyReferentCNAInput]
        ) {
          updateCompany(guid: $guid, referentsCNA: $referentsCNA)
        }
      `,
      variables: {
        guid: this.company.guid,
        referentsCNA: [companyReferentCNA],
      },
    });

    if (res.data.updateCompany) {
      this.$emit("update");
      this.onClose();
      this.showSuccess("Referente CNA correttamente associato");
    } else {
      this.showError("Errore durante l'associazione del Referente CNA");
    }
    this.loading = false;
  }

  disableConfirm() {
    if (this.selectedUsers.length == 0) {
      return true;
    } else {
      return this.area == null && this.selectedServices.length == 0;
    }
  }

  onClose() {
    this.resetSelectedUsers();
    this.$emit("close");
  }
}
