
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import ServicesSelector from "@/components/ServicesSelector.vue";
import ServicesSubSelector from "@/components/ServicesSubSelector.vue";
import CompanyServiceExpirationSelector from "@/components/CompanyServiceExpirationSelector.vue";
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";
import { ServiceStatus } from "@/utils/status";
import DatePicker from "@/components/DatePicker.vue";
import { RoleCodes } from "@/utils/roles";
import { formatForApi, getToday } from "@/plugins/moment";
import gql from "graphql-tag";
import moment, { Duration } from "moment";
import { CUSTOM_EXPIRATION } from "@/utils/services";

@Component({
  components: {
    Btn,
    Card,
    ServicesSelector,
    ServicesSubSelector,
    CompanyServiceExpirationSelector,
    SelectedServices,
    UsersSelector,
    SelectedUsers,
    DatePicker,
    Modal,
  },
  computed: {},
})
export default class AssignServicesToCompanyModal extends Vue {
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @State((state: StateStore) => state.data.selectedServicesSub)
  selectedServicesSub: Api.Service[];
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsers: Api.User[];
  @State((state: StateStore) => state.data.selectedUsers2)
  selectedUsers2: Api.User[];
  @Action updateCompanyServices;
  @Action sendEmailToCompanies;
  @Getter getRole: (code: string) => Api.Role;
  @Mutation resetSelectedServices;
  @Mutation resetSelectedUsers;
  @Mutation resetSelectedUsers2;
  @Mutation showError;
  @Mutation showSuccess;
  @Mutation removeSelectedUser;
  @Mutation removeSelectedUser2;
  @Mutation resetSelectedServicesSub;

  loading = false;

  @Prop()
  company;

  subserviceName = null;
  expiryDate = null;
  lastDate = null;
  duration = null;
  notes = null;
  booking = false;

  showConfirmationModal = null;

  getAdminFilter() {
    return [
      this.getRole(RoleCodes.ADMIN1).guid,
      this.getRole(RoleCodes.ADMIN2).guid,
    ];
  }

  getUserFilter() {
    return [this.getRole(RoleCodes.USER).guid];
  }

  expirationChanged(expiration) {
    if (this.lastDate != null) {
      const duration: Duration = expiration.duration as Duration;
      const newExpiryDate = moment(this.lastDate, "YYYY-MM-DD").add(duration);
      this.expiryDate = formatForApi(newExpiryDate);
      this.duration = expiration.value;
    }
  }

  expireDateChanged(date){
    this.expiryDate = date;
    this.duration = CUSTOM_EXPIRATION;
  }

  async onConfirm() {
    this.loading = true;
    const service = {};
    service["company_guid"] = this.company.guid;
    service["service_guid"] = this.selectedServices[0].guid;
    service["service_type_guid"] = this.selectedServices[0].servicetype.guid;
    service["status"] = ServiceStatus.ASSIGNED;
    service["service_sub_guid"] = this.selectedServicesSub[0].guid;
    service["employees_guid"] = _.map(this.selectedUsers, "guid");
    service["referent_guid"] = this.selectedUsers2[0].guid;
    service["notes"] = this.notes;
    service["due_date"] = this.expiryDate;
    service["latest_service_date"] = this.lastDate;
    service["duration"] = this.duration;
    service["booking"] = this.booking ? 1 : 0;

    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation company_update($guid: ID!, $services: [CompanyServiceInput]) {
          updateCompany(guid: $guid, services: $services)
        }
      `,
      variables: {
        guid: this.company.guid,
        services: [service],
      },
    });

    if (res.data.updateCompany) {
      await this.sendEmailToCompanies({
        companies: [this.company.guid],
        subject: "Nuovo servizio associato",
        text: "Un nuovo servizio è stato associato alla tua azienda",
      });
      this.$emit("update");
      this.onClose();
      this.resetSelectedServices();
      this.resetSelectedServicesSub();
      this.resetSelectedUsers();
      this.resetSelectedUsers2();
      this.showSuccess("Servizio correttamente associato");
    } else {
      this.showError("Errore durante l'associazione del servizio");
    }
    this.loading = false;
  }

  getMinDate() {
    return getToday();
  }

  getFormattedDate(date) {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  disableConfirm() {
    return this.selectedServices.length == 0 || this.selectedUsers2.length == 0;
  }

  onClose() {
    this.resetSelectedServices();
    this.resetSelectedUsers();
    this.resetSelectedUsers2();
    this.$emit("close");
  }

  getSelectedServiceGuid() {
    if (this.selectedServices[0] == null) {
      return "0";
    } else {
      return this.selectedServices[0].guid;
    }
  }
}
