
import Btn from "@/components/Btn.vue";
import Modal from "@/components/Modal.vue";
import gql from "graphql-tag";
import _ from "lodash";
import validator from "validator";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    Btn,
  },
  watch: {},
})
export default class EditCompanyContactsModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;

  isFormValid = false;
  allowSave = false;

  @Prop()
  company;
  @Prop({ default: "all" })
  editSection;

  editCompany = null;

  async mounted() {
    this.editCompany = this.company;
  }

  onClose() {
    this.allowSave = false;
    this.$emit("close");
  }

  resetValues() {
    (this.$refs.editCompanyForm as Vue & { reset: () => void })?.reset();
  }

  async updateCompany() {
    if (
      (this.$refs.editCompanyForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation company_update($guid: ID!, $notes: String) {
            updateCompany(guid: $guid, notes: $notes)
          }
        `,
        variables: {
          guid: this.editCompany.guid,

          notes: this.editCompany.notes,
        },
      });

      if (res.data.updateCompany) {
        this.$emit("companyUpdated", res.data.updateCompany);
        this.showSuccess("Azienda modificata correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante modifica azienda");
      }
    }
  }
}
