
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import CompanyData from "@/components/CompanyData.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { Btn, Card, CompanyData },
})
export default class ManageCompaniesDetailsView extends Vue {
  companyGuid = null;

  async mounted() {
    this.companyGuid = this.$route.params.companyGuid;
  }
}
