
import Btn from "@/components/Btn.vue";
import Modal from "@/components/Modal.vue";
import gql from "graphql-tag";
import _ from "lodash";
import ServicesSelector from "@/components/ServicesSelector.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import Vue from "vue";
import Component from "vue-class-component";
import * as Api from "@/types/graphql";
import { Prop } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    Modal,
    ServicesSelector,
    SelectedServices,
    Btn,
  },
  watch: {},
})
export default class AddCompanyDocumentModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation resetSelectedServices;
  @State((state: StateStore) => state.data.selectedServices)
  selectedServicesState: Api.Service[];

  isFormValid = false;
  allowSave = false;

  @Prop()
  company;
  @Prop({ default: "all" })
  editSection;

  editCompany = null;
  selectedService = null;
  document = null;

  documentRules = [(v) => !!v || "Inserire un URL"];

  async mounted() {
    this.editCompany = this.company;
    await this.resetSelectedServices();
  }

  onClose() {
    this.allowSave = false;
    this.$emit("close");
  }

  resetValues() {
    (this.$refs.editCompanyForm as Vue & { reset: () => void })?.reset();
  }

  async updateCompany() {
    if (
      (this.$refs.editCompanyForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      const document = {};
      document["company_guid"] = this.editCompany.guid;
      document["folder_link"] = this.document;
      document["service_guid"] = this.selectedServicesState[0]["guid"];

      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation company_update(
            $guid: ID!
            $documents: [CompanyDocumentInput]
          ) {
            updateCompany(guid: $guid, documents: $documents)
          }
        `,
        variables: {
          guid: this.editCompany.guid,
          documents: [document],
        },
      });

      if (res.data.updateCompany) {
        this.$emit("companyUpdated", res.data.updateCompany);
        this.showSuccess("Azienda modificata correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante modifica azienda");
      }
    }
  }
}
