
import Btn from "@/components/Btn.vue";
import Modal from "@/components/Modal.vue";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {
    Modal,
    Btn,
  },
  watch: {},
})
export default class EditCompanyRegistryModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;

  isFormValid = false;
  allowSave = false;

  @Prop()
  company;

  editCompany = null;

  ragsocRules = [(v) => !!v || "Inserire una ragione fiscale"];
  taxCodeRules = [(v) => !!v || "Inserire il Codice Fiscale"];
  vatRules = [];
  addressRules = [(v) => !!v || "Inserire l'indirizzo"];
  cityRules = [(v) => !!v || "Inserire il comune"];
  provinceRules = [(v) => !!v || "Inserire la provincia"];

  async mounted() {
    this.editCompany = this.company;
  }

  onClose() {
    this.allowSave = false;
    this.$emit("close");
  }

  resetValues() {
    (this.$refs.editCompanyForm as Vue & { reset: () => void })?.reset();
  }

  async updateCompany() {
    if (
      (this.$refs.editCompanyForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation company_update(
            $guid: ID!
            $ragsoc: String
            $vat: String
            $taxCode: String
            $address: String
            $city: String
            $prov: String
          ) {
            updateCompany(
              guid: $guid
              ragsoc: $ragsoc
              vat_number: $vat
              tax_code: $taxCode
              address: $address
              city: $city
              prov: $prov
            )
          }
        `,
        variables: {
          guid: this.editCompany.guid,
          ragsoc: _.capitalize(this.editCompany.ragsoc),
          vat: this.editCompany["vat_number"],
          taxCode: this.editCompany["tax_code"],
          address: _.capitalize(this.editCompany.address),
          city: _.capitalize(this.editCompany.city),
          prov: this.editCompany.prov,
        },
      });

      if (res.data.updateCompany) {
        this.$emit("companyUpdated", res.data.updateCompany);
        this.showSuccess("Azienda modificata correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante modifica azienda");
      }
    }
  }
}
