
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Modal from "@/components/Modal.vue";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { getReferentName } from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import HandleCompanyRoleModal from "@/components/HandleCompanyRoleModal.vue";
import AddUserToCognitoModal from "@/components/AddUserToCognitoModal.vue";
import Component from "vue-class-component";
import { Mutation, State, Getter } from "vuex-class";
import { State as StateStore } from "../store";
import * as Api from "@/types/graphql";
import { StatusCodes } from "@/utils/status";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    HandleCompanyRoleModal,
    AddUserToCognitoModal,
  },
})
export default class CompanyReferentsTable extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.companyReferents) referents;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Mutation showSuccess;
  @Mutation showError;

  @Prop()
  company;

  loading = false;

  sendingEmailUser = null;
  showSendingEmailModal = null;
  sendingEmailSubject = "";
  sendingEmailText = "";

  showRemoveReferentModal = null;

  showChangingRoleUserModal = null;
  changingRoleUser = null;
  changingRoleUserLoading = false;

  showActivatingUserModal = null;
  activatingUser = null;

  headers = [
    {
      text: "Nome",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Referente CNA",
      align: "start",
      sortable: false,
      value: "referent",
    },
    {
      text: "Telefono",
      align: "start",
      sortable: false,
      value: "phone",
    },
    {
      text: "Utenza",
      align: "start",
      sortable: false,
      value: "authentication_guid",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];

  capitalize(text) {
    return _.capitalize(text);
  }

  hasReferentName(user) {
    return !_.isEmpty(getReferentName(user));
  }

  getReferentName(user) {
    if (this.hasReferentName(user)) {
      return getReferentName(user);
    }
    return "Nessun referente";
  }

  setSending(item) {
    if (_.isNil(item)) {
      this.selected = [];
    } else {
      this.selected = [item];
    }
    this.showSendingEmailModal = item;
  }

  setChangingRoleUser(user) {
    this.changingRoleUser = user;
    this.showChangingRoleUserModal = user;
  }

  setActivatingUser(user) {
    this.activatingUser = user;
    this.showActivatingUserModal = user;
  }

  getModalKey() {
    return Math.random();
  }

  async sendEmail() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation sendEmailToUser(
          $guid: ID!
          $subject: String!
          $text: String!
        ) {
          sendEmailToUser(guid: $guid, subject: $subject, text: $text)
        }
      `,
      variables: {
        guid: this.selected[0].guid,
        subject: this.sendingEmailSubject,
        text: this.sendingEmailText,
      },
    });
    this.loading = false;
    this.setSending(null);
    if (res.data.sendEmailToUser) {
      this.showSuccess("Email inviata correttamente");
      this.sendingEmailSubject = "";
      this.sendingEmailText = "";
    } else {
      this.showError("Errore invio email");
    }
  }

  isAdmin() {
    return checkUserRole(this.user, [RoleCodes.ADMIN1, RoleCodes.ADMIN2]);
  }
}
