
import Btn from "@/components/Btn.vue";
import Avatar from "@/components/Avatar.vue";
import Modal from "@/components/Modal.vue";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import * as Api from "@/types/graphql";
import { Prop } from "vue-property-decorator";
import { Mutation, State, Getter } from "vuex-class";
import { State as StateStore } from "../store";
import validator from "validator";
import { getToday } from "@/plugins/moment";
import UsersSelector from "@/components/UsersSelector.vue";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import GenderSelector from "@/components/GenderSelector.vue";
import DatePicker from "@/components/DatePicker.vue";
import { StatusCodes } from "@/utils/status";

enum ModalStatus {
  SearchTaxCode = "SearchTaxCode",
  UserExists = "UserExists",
  CreateUser = "CreateUser",
}

@Component({
  components: {
    Modal,
    Btn,
    UsersSelector,
    GenderSelector,
    DatePicker,
    Avatar,
  },
  watch: {},
})
export default class AddCompanyEmployeeModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;
  @Getter getRole: (code: string) => Api.Role;
  @Getter getStatus: (code: string) => Api.UserStatus;

  isFormValid = false;
  loading = false;

  @Prop()
  company;

  status: ModalStatus = ModalStatus.SearchTaxCode;

  userstatus = [];
  taxCode = null;
  users = [];
  selectedUserId = "";

  newUser = {
    name: null,
    surname: null,
    email: null,
    phone: null,
    facilitator: null,
    company: null,
    birthday: null,
    gender: null,
    taxCode: null,
    status: null,
  };

  nameRules = [(v) => !!v || "Inserire un nome"];
  surnameRules = [(v) => !!v || "Inserire il cognome"];
  emailRules = [];
  phoneRules = [];
  companyRoleRules = [(v) => !!v || "Inserire il ruolo in azienda"];
  facilitatorRules = [
    (v) => !!this.newUser.facilitator || "Selezionare il Referente CNA",
  ];
  genderRules = [(v) => !!v || "Seleziona un valore"];
  birthdayRules = [(v) => !!v || "Inserire la data di nascita"];
  taxCodeRules = [(v) => !!v || "Inserire il codice fiscale"];

  getTitle() {
    return "Aggiungi nuovo addetto";
  }

  getPositiveButton() {
    switch (this.status) {
      case ModalStatus.SearchTaxCode:
        return "Verifica";
      case ModalStatus.CreateUser:
        return "Aggiungi";
      case ModalStatus.UserExists:
        return "Conferma";
    }
  }

  onConfirm() {
    console.log("onConfirm", this.status);
    switch (this.status) {
      case ModalStatus.SearchTaxCode:
        this.userstatus.push(this.getStatus(StatusCodes.USER_DIP_AZ));
        this.userstatus.push(this.getStatus(StatusCodes.USER_REF_AZ));
        this.handleTaxCode();
        break;
      case ModalStatus.CreateUser:
        this.handleNewUser();
        break;
      case ModalStatus.UserExists:
        this.handleExistingUser();
        break;
    }
  }

  async handleTaxCode() {
    if (
      (this.$refs.taxCodeForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      console.log(this.taxCode);
      this.newUser.taxCode = this.taxCode;
      this.loading = true;
      const usersRes = await this.$apollo.query({
        query: require("@/graphql/getUsersByTaxCode.graphql"),
        fetchPolicy: "no-cache",
        variables: {
          taxCode: this.taxCode,
        },
      });
      this.loading = false;
      const resultUsers = _.filter(usersRes.data.UsersByTaxCode, (user) => {
        return checkUserRole(user, [RoleCodes.USER]);
      });
      if (resultUsers.length == 0) {
        this.status = ModalStatus.CreateUser;
      } else {
        this.selectedUserId = resultUsers[0].guid;
        this.users = resultUsers;
        this.newUser.status = this.getStatus(StatusCodes.USER_REF_AZ).guid;
        this.status = ModalStatus.UserExists;
      }
    }
  }

  async handleNewUser() {
    if (
      (this.$refs.userForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      this.loading = true;
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation insertUserNoCognito(
            $name: String
            $surname: String
            $email: String
            $phone: String
            $taxCode: String
            $birthday: String
            $gender: String
            $facilitatorGuid: String
          ) {
            insertUserNoCognito(
              name: $name
              surname: $surname
              email: $email
              phone: $phone
              tax_code: $taxCode
              birth_date: $birthday
              gender: $gender
              facilitator_guid: $facilitatorGuid
            )
          }
        `,
        variables: {
          name: _.capitalize(this.newUser.name),
          surname: _.capitalize(this.newUser.surname),
          email: this.newUser.email,
          phone: this.newUser.phone,
          taxCode: this.newUser.taxCode,
          birthday: this.newUser.birthday,
          gender: this.newUser.gender,
          facilitatorGuid: this.newUser.facilitator.guid,
        },
      });
      if (res.data.insertUserNoCognito) {
        this.selectedUserId = res.data.insertUserNoCognito;
        this.handleExistingUser();
      } else {
        this.loading = false;
        this.showError("Errore durante inserimento addetto");
      }
    }
  }

  async handleExistingUser() {
    let res;
    let result = "";
    if (this.newUser.status == this.getStatus(StatusCodes.USER_REF_AZ).guid) {
      const referents = [{ guid: this.selectedUserId }];
      res = await this.$apollo.mutate({
        mutation: gql`
          mutation company_update($guid: ID!, $referents: [UserInput]) {
            updateCompany(guid: $guid, referents: $referents)
          }
        `,
        variables: {
          guid: this.company.guid,
          referents: referents,
        },
      });
      result = "Referente";
    }
    if (this.newUser.status == this.getStatus(StatusCodes.USER_DIP_AZ).guid) {
      const employees = [{ guid: this.selectedUserId }];
      res = await this.$apollo.mutate({
        mutation: gql`
          mutation company_update($guid: ID!, $employees: [UserInput]) {
            updateCompany(guid: $guid, employees: $employees)
          }
        `,
        variables: {
          guid: this.company.guid,
          employees: employees,
        },
      });
      result = "Dipendente";
    }
    await this.$apollo.mutate({
      mutation: require("@/graphql/setUserStatus.graphql"),
      variables: {
        guid: this.selectedUserId,
        role: this.getRole(RoleCodes.USER).guid,
        status: this.newUser.status,
      },
    });

    if (res.data.updateCompany) {
      this.$emit("update");
      this.showSuccess(result + " aggiunto correttamente");
      this.onClose();
    } else {
      this.showError("Errore durante inserimento " + result);
    }
    this.loading = false;
  }

  onClose() {
    this.$emit("close");
  }

  getUserMaxDate() {
    return getToday();
  }

  getRolesFilter() {
    return [
      this.getRole(RoleCodes.ADMIN1).guid,
      this.getRole(RoleCodes.ADMIN2).guid,
    ];
  }
}
