
import Btn from "@/components/Btn.vue";
import Modal from "@/components/Modal.vue";
import gql from "graphql-tag";
import _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";
import validator from "validator";

@Component({
  components: {
    Btn,
    Modal,
  },
  watch: {
    company(val) {
      this.showDocuments(val);
    },
    customList(val) {
      this.items = val;
    },
  },
  computed: {},
})
export default class CompanyDocuments extends Vue {
  @Mutation showError;
  @Mutation showSuccess;

  @Prop()
  company;

  services = [];
  mappedDocuments = {};

  showDeletingModal = null;
  deletingDocument = null;

  mounted() {
    this.showDocuments(this.company);
  }

  showDocuments(company) {
    this.mappedDocuments = _.groupBy(company.documents, "service.des");
    this.services = _.keys(this.mappedDocuments);
  }

  setRemoving(document) {
    this.deletingDocument = document;
    this.showDeletingModal = document;
  }

  async remove() {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation deleteCompanyDocument($guid: String) {
          deleteCompanyDocument(guid: $guid)
        }
      `,
      variables: {
        guid: this.deletingDocument.guid,
      },
    });

    if (res.data.deleteCompanyDocument) {
      this.$emit("update");
      this.showSuccess("Documento eliminato correttamente");
      this.showDeletingModal = null;
      this.deletingDocument = null;
    } else {
      this.showError("Errore durante eliminazione documento");
    }
  }

  isUrl(document) {
    return validator.isURL(document.folder_link);
  }

  openLink(document) {
    if (this.isUrl(document)) {
      window.open(document.folder_link, "_blank");
    }
  }
}
